<template>
    <v-card elevation="0" class="my-5">
        <v-row align-content="center" justify="center">
            <h2>
                {{ getRecordMinutes }}:{{ getRecordSeconds }}
            </h2>

            <section id="bee-voice-recording-container" class="w-100 my-3" ref="beeVoiceRecorder"></section>

            <!-- Recording actions  -->
            <v-card rounded="lg" color="rgba(232, 107, 89, 0.1)" elevation="0" class="w-100 pa-5" v-if="!recordFinished">
                <!-- Start recording button -->
                <v-row align-content="center" justify="center" class="pa-3" v-if="!isRecording">
                    <v-btn
                        fab
                        color="#E86B59"
                        elevation="0"
                        @click="startRecording"
                        x-large
                    >
                        <v-icon color="white">mdi-microphone</v-icon>
                    </v-btn>
                </v-row>

                <!-- reset/finish buttons -->
                <!-- Reset -->
                <v-row v-if="isRecording" class="w-100" align-content="center" justify="center">
                    <v-btn
                        fab
                        color="white"
                        elevation="0"
                        @click="resetRecording"
                        class="ma-3"
                    >
                        <v-icon color="deep-orange accent-2">mdi-close</v-icon>
                    </v-btn>

                    <!-- Finish -->
                    <v-btn
                        fab
                        color="#E86B59"
                        elevation="0"
                        @click="stopRecording"
                        x-large
                    >
                        <v-icon color="white">mdi-check</v-icon>
                    </v-btn>
                </v-row>
            </v-card>

            <!-- Actions record finished -->
            <v-card rounded="lg" color="white" elevation="0" class="w-100 pa-5" v-else>
                <v-row align-content="center" justify="center" class="pa-3">
                    <v-btn
                        fab
                        color="deep-purple lighten-5"
                        elevation="0"
                        class="ma-3"
                        small
                        @click="restartAudio"
                    >
                        <v-icon color="deep-purple">mdi-restart</v-icon>
                    </v-btn>

                    <!-- Play/Puase button -->
                    <v-btn
                        fab
                        color="white"
                        elevation="3"
                        class="mx-1"
                        large
                        @click="hanldePlayPauseRecording"
                    >
                        <v-icon color="#E86B59" x-large v-if="!isPlay">mdi-play</v-icon>
                        <v-icon color="#E86B59" x-large v-else>mdi-pause</v-icon>
                    </v-btn>

                    <v-btn
                        v-if="!disableDelete"
                        fab
                        color="red lighten-5"
                        elevation="0"
                        class="ma-3"
                        small
                        @click="resetRecording"
                    >
                        <v-icon color="#E86B59">mdi-trash-can</v-icon>
                    </v-btn>
                </v-row>
            </v-card>

        </v-row>
    </v-card>
</template>

<script>
import WaveSurfer from 'wavesurfer.js'
import WaveSurferRecordPlugin from 'wavesurfer.js/dist/plugins/record'

export default {
    name: 'VoiceRecording',
    props: {
        isRecorded: {
            type: Boolean,
            default: false
        },
        voiceRecordURL: {
            type: String,
            default: undefined
        },
        disableDelete: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isRecording: false,
            recordFinished: false,
            waveSurfer: undefined,
            recorder: undefined,
            isPlay: false,
            counter: {
                minutes: 0,
                seconds: 0,
                counterInterval: undefined
            },
        }
    },
    methods: {
        createGraph(voiceRecordURL = null) {
            const container = this.$refs.beeVoiceRecorder
            this.waveSurfer = WaveSurfer.create({
                container: container,
                waveColor: '#E7EAEE',
                progressColor: '#64748B',
                barWidth: 3,
                barGap: 1,
                barRadius: 2,
                cursorColor: '#D64A3B',
                url: voiceRecordURL
            })

            this.waveSurfer.on('finish', () => {
                this.restartAudio()
            })

            this.waveSurfer.on('audioprocess', (timeUpdate) => {
                if(this.isRecorded) {
                    this.counter.seconds = Number(timeUpdate.toFixed(0))
                    if(this.counter.seconds >= 60) {
                        if(this.counter.minutes === 0) {
                            this.counter.minutes += 1                            
                        }else {
                            this.counter.minutes = Number(this.counter.seconds / 60 ).toFixed(0)
                        }
                        this.counter.seconds = Number(this.counter.seconds - (this.counter.minutes * 60)).toFixed()
                    }
                }
            })

            this.recorder = this.waveSurfer.registerPlugin(WaveSurferRecordPlugin.create())

            this.recorder.on('record-end', (blob) => {
                    this.$emit('on-audio-ready', blob)
                    this.removeChildFromRecorder()
                    const recordedUrl = URL.createObjectURL(blob)
                    this.waveSurfer = WaveSurfer.create({
                        container,
                        waveColor: '#E7EAEE',
                        progressColor: '#64748B',
                        url: recordedUrl,
                        barWidth: 3,
                        barGap: 1,
                        barRadius: 2,
                        cursorColor: '#D64A3B'
                    })
                    this.waveSurfer.on('interaction', () => this.waveSurfer.playPause())
                    this.isRecording = false
                    this.recordFinished = true

                })

        },
        startRecording() {
            if(this.recordFinished) {
                this.removeChildFromRecorder()
                this.createGraph()
            }
            this.recorder.startRecording().then(() => {
                this.isRecording = true
                this.startCounter()
            })
        },
        stopRecording() {
            if(this.recorder.isRecording()) {
                this.recorder.stopRecording()
                this.isRecording = false
                this.stopCounter()
                this.$emit('recording-finished')
            }
        },
        restartAudio() {
            this.waveSurfer.setTime(0)
            this.isPlay = false
            this.stopCounter()
            this.resetCounter()
        },
        resetRecording() {
            this.audioFile = null
            this.removeChildFromRecorder()
            this.createGraph()
            this.isRecording = false
            this.recordFinished = false
            this.isPlay = false
            this.stopCounter()
            this.resetCounter()
            this.$emit('on-reset-record')
        },
        hanldePlayPauseRecording() {
            this.waveSurfer.playPause()
            this.isPlay = !this.isPlay
        },
        removeChildFromRecorder() {
            this.resetCounter()
            const container = this.$refs.beeVoiceRecorder
            if(container.firstChild) {
                container.removeChild(container.firstChild)
            }
        },
        // method to create counter of minutes, seconds and milliseconds
        startCounter() {
            if(this.isRecording || this.isRecorded) {
                this.counter.counterInterval = setInterval(() => {
                    this.updateCounter()
                }, 1000)
            }
        },
        stopCounter() {
            clearInterval(this.counter.counterInterval)
        },
        updateCounter() {
            this.counter.seconds++
            if(this.counter.seconds >= 60) {
                this.counter.minutes++
                this.counter.seconds = 0
            }
        },
        resetCounter() {
            this.counter = {
                minutes: 0,
                seconds: 0,
                counterInterval: undefined
            }
        }
    },
    computed: {
        getRecordMinutes() {
            if(this.counter.minutes < 10) {
                return `0${this.counter.minutes}`
            }

            return this.counter.minutes
        },
        getRecordSeconds() {
            if(this.counter.seconds < 10) {
                return `0${this.counter.seconds}`
            }

            return this.counter.seconds
        }
    },
    watch: {
        isRecorded(val) {
            this.removeChildFromRecorder()
            if(val) {
                this.createGraph(this.voiceRecordURL)
                this.recordFinished = true
            } else {
                this.createGraph()
                this.recordFinished = false
            }
        },
        voiceRecordURL(val) {
            this.removeChildFromRecorder()
            if(val) {
                this.createGraph(val)
                this.recordFinished = true
            } else {
                this.createGraph()
                this.recordFinished = false
            }
        }
    },
    mounted() {
        if(this.isRecorded) {
            this.recordFinished = true
        }
        this.createGraph(this.voiceRecordURL)
    }
}
</script>

<style lang="scss" scoped>
    #bee-voice-recording-container {
        width: 100%;
        min-height: 90px;
        padding: 12px;
        background-color: #F7F8F9;
    }
</style>